import { useContext, useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
import { WorkspaceContext, WorkspaceContextType } from 'src/context/workspace-context';
// locales
import { useLocales } from 'src/locales';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';


// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  productcode: icon('ic_productcode'),
  admin: icon('ic_admin'),
  settings: icon('ic_settings'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  const { workspace } = useContext(WorkspaceContext) as WorkspaceContextType;

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: t('dashboard'),
        items: [
          {
            title: t('dashboard_general'),
            path: paths.dashboard.root,
            icon: ICONS.dashboard,
          },
          {
            title: t('dashboard_detailed'),
            path: paths.dashboard.admin,
            icon: ICONS.dashboard,
            roles: ['SuperAdmin'],
          },
        ],
      },
      {
        subheader: t('merchant'),
        items: [
          {
            title: t('overview'),
            path: paths.merchant.details,
            icon: ICONS.settings,
            roles: ['MerchantAdmin','SuperAdmin'],
          },
          {
            title: t('product'),
            path: paths.product.root,
            icon: ICONS.product,
            roles: ['MerchantAdmin','SuperAdmin'],
          },
          {
            title: t('productcode'),
            path: paths.productCode.root,
            icon: ICONS.productcode,
            roles: ['MerchantAdmin','SuperAdmin'],
          },
          {
            title: t('redeem'),
            path: paths.redeem.root,
            icon: ICONS.invoice,
          },
          {
            title: t('merchantreport'),
            path: paths.merchantreport.root,
            icon: ICONS.analytics,
            roles: ['MerchantAdmin','SuperAdmin'],
          },
          {
            title: t('user'),
            path: paths.merchantuser.root,
            icon: ICONS.user,
            roles: ['MerchantAdmin','SuperAdmin'],
          },
        ],
      },
      {
        subheader: t('admin'),
        items: [
          {
            title: t('merchant'),
            path: paths.merchant.root,
            icon: ICONS.ecommerce,
            roles: ['SuperAdmin'],
          },
          {
            title: t('administrators'),
            path: paths.user.root,
            icon: ICONS.admin,
            roles: ['SuperAdmin'],
          },
          {
            title: t('user'),
            path: paths.mobileuser.root,
            icon: ICONS.user,
            roles: ['SuperAdmin'],
          },
          {
            title: t('greetie'),
            path: paths.greetie.root,
            icon: ICONS.order,
            roles: ['SuperAdmin'],
          },
          {
            title: t('voucher'),
            path: paths.voucher.root,
            icon: ICONS.invoice,
            roles: ['SuperAdmin'],
          },
          {
            title: t('salesreport'),
            path: paths.salesreport.root,
            icon: ICONS.analytics,
            roles: ['SuperAdmin'],
          },
          {
            title: t('report'),
            path: paths.report.root,
            icon: ICONS.analytics,
            roles: ['SuperAdmin'],
          },
          {
            title: t('marketing'),
            path: paths.marketing.root,
            icon: ICONS.mail,
            roles: ['SuperAdmin'],
          },
        ],
      },
    ],
    [t]
  );

  return data;
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from 'src/store/index';

import { IntegrationDto, IntegrationService } from 'src/api';
import { getErrorMessage } from 'src/utils/error';
import { setMerchantIntegrations } from './merchantSlice';

export interface IntegrationState {
  loading: boolean;
  hasError: boolean;
  error?: string;
  integrations: IntegrationDto[];
}

const initialState: IntegrationState = {
  loading: false,
  hasError: false,
  error: '',
  integrations: [],
};

export const integrationSlice = createSlice({
  name: 'integration',
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.hasError = false;
      }
      state.loading = action.payload;
    },
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.error = `${action.payload}`;
    },
    setList: (state, action: PayloadAction<IntegrationDto[]>) => {
      state.integrations = action.payload;
    },
    set: (state, action: PayloadAction<IntegrationDto>) => {
      state.integrations = state.integrations.map((m) => {
        if (m.id === action.payload) return action.payload;
        return m;
      });
    },
    add: (state, action: PayloadAction<IntegrationDto>) => {
      state.integrations.push(action.payload);
    },
    update: (state, action: PayloadAction<IntegrationDto>) => {
      state.integrations = state.integrations.map((merchantReport) =>
        merchantReport.id === action.payload.id ? action.payload : merchantReport
      );
    },
    remove: (state, action: PayloadAction<string>) => {
      state.integrations = state.integrations.filter((sr) => sr.id !== action.payload);
    },
  },
});

export const { loading, hasError, setList, set, add, update, remove } = integrationSlice.actions;

export const getIntegrations =
  (merchantId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(loading(true));
      const response = await IntegrationService.getApiIntegration(merchantId);
      dispatch(setList(response));
    } catch (e) {
      dispatch(hasError(getErrorMessage(e)));
    } finally {
      dispatch(loading(false));
    }
  };

export const getIntegration =
  (integrationId: string): AppThunk =>
  async (dispatch) => {
    try {
      const response = await IntegrationService.getApiIntegration1(integrationId);
      dispatch(set(response));
      return response;
    } catch (e) {
      dispatch(hasError(getErrorMessage(e)));
      throw e;
    }
  };

export const createIntegration =
  (integration: IntegrationDto): AppThunk =>
  async (dispatch, getState) => {
    try {
      const response = await IntegrationService.postApiIntegration(integration);
      dispatch(add(response));
      dispatch(
        setMerchantIntegrations({
          id: integration.merchantId!,
          integrations: getState().integration.integrations,
        })
      );
      return true;
    } catch (e) {
      dispatch(hasError(getErrorMessage(e)));
      throw e;
    }
  };

export const updateIntegration =
  (integration: IntegrationDto): AppThunk =>
  async (dispatch, getState) => {
    try {
      const response = await IntegrationService.putApiIntegration(integration.id!, integration);
      dispatch(update(response));
      dispatch(
        setMerchantIntegrations({
          id: integration.merchantId!,
          integrations: getState().integration.integrations,
        })
      );
      return true;
    } catch (e) {
      dispatch(hasError(getErrorMessage(e)));
      throw e;
    }
  };

export const removeIntegration =
  (integrationId: string, merchantId: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      await IntegrationService.deleteApiIntegration(integrationId);
      dispatch(remove(integrationId));
      dispatch(
        setMerchantIntegrations({
          id: merchantId!,
          integrations: getState().integration.integrations,
        })
      );
      return true;
    } catch (e) {
      dispatch(hasError(getErrorMessage(e)));
      throw e;
    }
  };

export const integrationStateSelector = (state: RootState): IntegrationState => state.integration;

export default integrationSlice.reducer;

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AgreementDto } from '../models/AgreementDto';
import type { AssetDto } from '../models/AssetDto';
import type { MerchantDto } from '../models/MerchantDto';
import type { MerchantProductDto } from '../models/MerchantProductDto';
import type { RegisterMerchantDto } from '../models/RegisterMerchantDto';
import type { UserDto } from '../models/UserDto';
import type { VoucherDto } from '../models/VoucherDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MerchantService {

    /**
     * @returns MerchantDto OK
     * @throws ApiError
     */
    public static getApiMerchant(): CancelablePromise<Array<MerchantDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/merchant',
        });
    }

    /**
     * @param requestBody 
     * @returns MerchantDto OK
     * @throws ApiError
     */
    public static postApiMerchant(
requestBody?: MerchantDto,
): CancelablePromise<MerchantDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/merchant',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns MerchantDto OK
     * @throws ApiError
     */
    public static getApiMerchant1(
id: string,
): CancelablePromise<MerchantDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/merchant/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns MerchantDto OK
     * @throws ApiError
     */
    public static putApiMerchant(
id: string,
requestBody?: MerchantDto,
): CancelablePromise<MerchantDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/merchant/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns number OK
     * @throws ApiError
     */
    public static deleteApiMerchant(
id: string,
): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/merchant/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param formData 
     * @returns AssetDto OK
     * @throws ApiError
     */
    public static postApiMerchantLogo(
id: string,
formData?: {
file?: Blob;
},
): CancelablePromise<AssetDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/merchant/{id}/logo',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param id 
     * @param formData 
     * @returns AssetDto OK
     * @throws ApiError
     */
    public static putApiMerchantLogo(
id: string,
formData?: {
file?: Blob;
},
): CancelablePromise<AssetDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/merchant/{id}/logo',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param id 
     * @returns MerchantProductDto OK
     * @throws ApiError
     */
    public static getApiMerchantProduct(
id: string,
): CancelablePromise<Array<MerchantProductDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/merchant/{id}/product',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns AgreementDto OK
     * @throws ApiError
     */
    public static postApiMerchantAgreement(
id: string,
requestBody?: AgreementDto,
): CancelablePromise<Array<AgreementDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/merchant/{id}/agreement',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns MerchantDto OK
     * @throws ApiError
     */
    public static postApiMerchantApprove(
id: string,
): CancelablePromise<MerchantDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/merchant/{id}/approve',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param ownerId 
     * @returns MerchantDto OK
     * @throws ApiError
     */
    public static postApiMerchantOwner(
id: string,
ownerId?: string,
): CancelablePromise<MerchantDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/merchant/{id}/owner',
            path: {
                'id': id,
            },
            query: {
                'ownerId': ownerId,
            },
        });
    }

    /**
     * @param merchantId 
     * @returns VoucherDto OK
     * @throws ApiError
     */
    public static getApiMerchantRedeems(
merchantId?: string,
): CancelablePromise<Array<VoucherDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/merchant/redeems',
            query: {
                'merchantId': merchantId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns UserDto OK
     * @throws ApiError
     */
    public static postApiMerchantRegister(
requestBody?: RegisterMerchantDto,
): CancelablePromise<UserDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/merchant/register',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
